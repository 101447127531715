<template>
  <div class="content_library flex flex-col min-h-screen">
    <!-- Having a key forces different full paths to re-render the components. We need this so that collections linking to other collections trigger the lifecycle hooks. -->
    <router-view :key="$route.fullPath"></router-view>
    <PoweredBy />
  </div>
</template>

<script>
import PoweredBy from './components/PoweredBy.vue'

export default {
  name: 'App',
  components: {
    PoweredBy,
  },
}
</script>

<style>
.content_library {
  @apply font-sans;
  @apply text-lg;
  @apply leading-loose;
  color: #4D5E6B;
}

.content_library a {
  color: #f36b23;
}

.content_library p {
  @apply mb-4;
}

.content_library ul {
  @apply mb-4;
}

.content_library li {
  /* We want to align the text inside each bullet and still have the bullet align with the left of the content area. */
  @apply list-outside;
  @apply list-disc;
  @apply ml-4;
}

/* These are "TIP" blocks. */
.content_library blockquote {
  @apply my-4;
  @apply p-4;
  @apply text-white;
  @apply rounded-lg;

  background: #2496b0;
}

.content_library blockquote p {
  @apply m-0;
}

.content_library blockquote strong {
  @apply text-2xl;
}

.content_library table {
  @apply table-auto;
  @apply border-collapse;
  @apply max-w-full;
  @apply mb-4;
}

.content_library thead th,
.content_library tbody td {
  @apply p-2;
  @apply border-solid;
  @apply border;
  border-color: #4d5e6b;
}

.content_library tfoot {
  @apply text-sm;
}

.content_library h3 {
  @apply font-bold;
  @apply text-pp-teal;
  @apply text-2xl;
  @apply mt-3;
  @apply mb-2;
}
</style>
