// This has been modified to only return the routes instead of the router so that we can access settings and set default options for the route.

const routes = [
  {
    // The `:uuid` will pass the value to the component as the `uuid` property.
    path: '/3d-anatomy/:uuid',
    name: '3D Anatomy',
    // This only loads the component as-needed.
    component: () => import(/* webpackChunkName: "contentAnatomy3D" */ '@/components/ContentAnatomy3D.vue'),
    props: true,
  },
  {
    path: '/anatomy-collection/:uuid',
    name: 'Anatomy Collection',
    component: () => import(/* webpackChunkName: "contentCollectionAnatomy" */ '@/components/ContentCollectionAnatomy.vue'),
    props: true,
  },
  {
    path: '/anatomy-library/:uuid',
    name: 'Anatomy Library',
    component: () => import(/* webpackChunkName: "contentCollectionAnatomyLibrary" */ '@/components/ContentCollectionAnatomyLibrary.vue'),
    props: true,
  },
  {
    path: '/article/:uuid',
    name: 'Article',
    component: () => import(/* webpackChunkName: "contentArticle" */ '@/components/ContentArticle.vue'),
    props: true,
  },
  {
    path: '/collection/:uuid',
    name: 'Collection',
    component: () => import(/* webpackChunkName: "contentCollection" */ '@/components/ContentCollection.vue'),
    props: true,
  },
  {
    path: '/document/:uuid',
    name: 'Document',
    component: () => import(/* webpackChunkName: "contentDocument" */ '@/components/ContentDocument.vue'),
    props: true,
  },
  {
    path: '/guide/:uuid',
    name: 'Guide',
    component: () => import(/* webpackChunkName: "contentGuide" */ '@/components/ContentGuide.vue'),
    props: true,
  },
  {
    path: '/image/:uuid',
    name: 'Image',
    component: () => import(/* webpackChunkName: "contentImage" */ '@/components/ContentImage.vue'),
    props: true,
  },
  {
    path: '/interactive-anatomy/:uuid',
    name: 'Interactive Anatomy',
    component: () => import(/* webpackChunkName: "contentAnatomyInteractive" */ '@/components/ContentAnatomyInteractive.vue'),
    props: true,
  },
  {
    path: '/playlist/:uuid',
    name: 'Playlist',
    component: () => import(/* webpackChunkName: "contentPlaylist" */ '@/components/ContentPlaylist.vue'),
    props: true,
  },
  {
    path: '/video/:uuid',
    name: 'Video',
    component: () => import(/* webpackChunkName: "contentVideo" */ '@/components/ContentVideo.vue'),
    props: true,
  }
]

export default routes
